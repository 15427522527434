@charset "utf-8";
/**
 * Created by henian.xu on 2017/11/8.
 *
 */

.badge {
    display: inline-block;
    background-color: $gray5;
    padding: 0 .5em;
    line-height: $line-height;
    border-radius: $body-font-size*$line-height/2;
    color: $color-text;
}

@each $key, $value in $colors {
    .badge-#{'' + $key} {
        $color: $color-text;
        @if (lightness_($value)<71) {
            $color: #fff;
        }
        background-color: $value;
        color: $color;

        &.plane{
            border-radius: .05rem;
            background-color: transparent;
            color: $value;
            border: 1px solid $value;
        }
    }
}
