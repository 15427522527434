@charset "utf-8";
/**
 * Created by henian.xu on 2018/4/19.
 *
 */

.panel{
    background-color: #fff;
    >.header{}
    >.body{}
    >.footer{
        padding: $padding-small $padding;
        border-top: 1px solid $color-body;
    }
}
