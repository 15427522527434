/**
 * Created by henian.xu on 2017/6/21.
 *
 */

* {
    border: none;
    font-size: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    text-rendering: geometricPrecision; //如何优化显示文本
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 0, 0, 0);

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

html {
    font-family: $font-family;
    font-size: $html-font-size;
}

body {
    font-size: $body-font-size;
    line-height: $line-height;
    background-color: $gray7;
    color: $color-text;
}

a {
    text-decoration: none;
}

