/**
 * Created by henian.xu on 2017/10/22.
 *
 */
$buttonHeight: .3rem;
a.btn {
    text-decoration: none;
}

.btn {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: $body-font-size;
    line-height: 1.2;
    padding: $padding-small $padding;
    min-height: $buttonHeight;
    cursor: pointer;

    &.fill {
        width: 100%;
    }
    &.radius {
        border-radius: .1rem;
        &.all {
            $radius: ($body-font-size * 1.2 + $padding-small * 2);
            @if ($radius < $buttonHeight) {
                $radius: $buttonHeight
            }
            border-radius: $radius/2;
        }
    }

    $size: (
        small:.75,
        big:1.1,
        more:1.2,
        super:1.5,
    );

    @each $key, $value in $size {
        &.#{$key} {
            font-size: $value * 100%;
            min-height: $buttonHeight * $value;
            //padding-left: $padding * $value;
            //padding-right: $padding * $value;
            padding: ($padding-small * $value) ($padding * $value);

            &.radius {
                border-radius: .1rem*$value;
                &.all {
                    $radius: ($body-font-size * 1.2 * $value + $padding-small * 2);
                    @if ($radius < $buttonHeight * $value) {
                        $radius: $buttonHeight * $value
                    }
                    border-radius: $radius / 2;
                }
            }
        }
    }

    > .f-icon {
        margin-right: .1rem;
    }
}

@each $key, $value in $colors {
    .btn-#{'' + $key} {
        $color: $color-text;
        @if (lightness_($value)<71) {
            $color: #fff;
        }
        background-color: $value;
        border-color: $value;
        color: $color;

        &.link {
            background-color: transparent;
            border: 1px solid transparent;
            color: $value;
            &.bor {
                border-color: $value;
            }
        }
    }
}

.btn-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .btn {
        flex: 1 1 auto;
        + .btn {
            margin-left: -1px;
        }
    }

    &.average {
        > .btn {
            width: 1%;
        }
    }
    &.interval {
        > .btn {
            + .btn {
                margin-left: $margin;
            }
        }
    }
}
