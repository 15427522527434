/**
 * Created by henian.xu on 2017/11/3.
 *
 */

.tool-bar {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;

    > .item {
        flex-grow: 1;
        text-align: center;
        width: 100%;

        + .item {
            border-left: 1px solid $color-border;
        }

        > .num {
            font-size: .3rem;
        }
        > .label {
            font-size: .24rem;
            > .f-icon {
                font-size: 120%;
            }
        }
    }
}
