/**
 * Created by henian.xu on 2017/7/4 0004.
 *
 */

@font-face {
    font-family: 'Fonticon';
    /*src: url('../fonts/fonticon.eot');
    src: url('../fonts/fonticon.eot') format('embedded-opentype'),
    url('../fonts/fonticon.woff2') format('woff2'),
    url('../fonts/fonticon.woff') format('woff'),
    url('../fonts/fonticon.ttf') format('truetype'),
    url('../fonts/fonticon.svg#fonticon') format('svg');*/
    src: url('~@/assets/fonts/fonticon.ttf') format('truetype'),
    url('~@/assets/fonts/fonticon.woff') format('woff');
}

.f-icon {
    @include make-icon;
}
