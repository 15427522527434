/**
 * Created by henian.xu on 2017/10/19 0019.
 *
 */

/* Make clicks pass-through */
.nprogress,
#nprogress {
    position: fixed;
    z-index: $z-index-8;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .0);
    //pointer-events: none; // 鼠标可以穿透
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bar {
        background: #29d;

        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: .05rem;
    }
    /* Fancy blur effect */
    .peg {
        display: block;
        position: absolute;
        right: 0;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px #29d, 0 0 5px #29d;
        opacity: 1.0;

        -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
        transform: rotate(3deg) translate(0px, -4px);
    }
    /* Remove these to get rid of the spinner */
    .spinner {
        position: relative;
        display: block;
        //position: fixed;
        z-index: 1031;
        top: .15rem;
        right: .15rem;
    }
}

.spinner-icon {
    position: relative;
    display: inline-block;
    width: 1.88rem;
    height: 1.88rem;
    box-sizing: border-box;
    //background-color: #c4d67c;
    border-radius: 50%;
    //box-shadow: -5px 5px 0px rgba(74, 89, 0, 0.2);

    //border: solid 2px transparent;
    //border-top-color: #29d;
    //border-left-color: #29d;
    //border-radius: 50%;

    //-webkit-animation: nprogress-spinner 400ms linear infinite;
    //animation: nprogress-spinner 400ms linear infinite;
    > .gif {
        width: 2rem;
        height: auto;
    }
    > .heart {
        position: absolute;
        left: 50%;
        top: .1rem;
        width: 1.38rem;
        height: 1.27rem;
        margin-left: -(1.38rem/2);
        background: transparent url("~@/assets/images/loading/heart.png") center no-repeat;
        background-size: 100% 100%;
        animation: ani-monkey 1.5s linear alternate infinite;
    }
    > .money01 {
        position: absolute;
        left: 80%;
        top: 1.1rem;
        width: .53rem;
        height: .53rem;
        background: transparent url("~@/assets/images/loading/money01.png") center no-repeat;
        background-size: 100% 100%;
        animation: ani-money01 1s linear alternate infinite;
    }
    > .money02 {
        position: absolute;
        left: -.25rem;
        top: 30%;
        width: .45rem;
        height: .45rem;
        background: transparent url("~@/assets/images/loading/money02.png") center no-repeat;
        background-size: 100% 100%;
        animation: ani-money02 1s linear alternate infinite;
    }
    > .shadow {
        position: absolute;
        left: 50%;
        bottom: .15rem;
        width: .78rem;
        height: .11rem;
        margin-left: -(.78rem/2);
        background: transparent url("~@/assets/images/loading/shadow.png") center no-repeat;
        background-size: 100% 100%;
        animation: shadow 1.5s linear alternate infinite;
    }
}

@keyframes ani-monkey {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(.08rem);
    }
}

@keyframes ani-money01 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-.08rem);
    }
}

@keyframes ani-money02 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-.08rem);
    }
}

@keyframes shadow {
    0% {
        transform: translateY(0) scale(.8);
    }
    100% {
        transform: translateY(.05rem) scale(1);
    }
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
