@charset "utf-8";
/**
 * Created by henian.xu on 2018/3/6.
 *
 */

$inputHeight: .6rem;
$fontSize: $body-font-size;
$inputTag: (
    '>input', '>select', '>textarea',
    '>.input', '>.select', '>.textarea'
);

.form {
    .form-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        line-height: $inputHeight;
        font-size: $fontSize;
        padding: $padding;
        border-top: 1px solid $color-border;
        background-color: #fff;

        &:first-child {
            border-top: 0;
        }

        > .asterisk {
            flex: 0 0 auto;
            display: none;
        }
        > .inner {
            flex: 1 1 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            > .label {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
            }
            > .body {
                width: 1%;
                flex: 1 1 auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > .content {
                    width: 1%;
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    min-height: .6rem;

                    #{$inputTag} {
                        width: 1%;
                        flex: 1 1 auto;
                        line-height: $inputHeight;

                        appearance: none;
                        border: 0;
                        outline: 0;
                        background-color: transparent;
                        &[readonly]{
                            background-color: $gray1;
                        }
                    }
                    > .f-icon {
                        min-width: $inputHeight;
                        text-align: center;
                        margin: 0;
                        font-size: $fontSize;
                    }
                    > .btn {
                        line-height: $inputHeight;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }

        &.required {
            > .asterisk {
                display: block;
            }
        }
        &.column {
            > .inner {
                > .label,
                > .body {
                    width: 100%;
                }
                &, .body > .content {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                }
            }
        }

        &.error {
            > .label {
                color: $color-red;
            }
            > .body {
                > .content {
                    #{$inputTag} {
                        color: $color-red;
                        &::placeholder {
                            color: $color-red;
                            opacity: .6;
                        }
                    }
                }
            }
        }

        &.ta-r {
            >.inner{
                >.body{
                    >.content{
                        justify-content: flex-end;
                    }
                }
            }
            .select{
                > select {
                    direction: rtl;
                    > option {
                        direction: ltr;
                    }
                }
            }
        }
    }
    .link.form-item,
    a.form-item {
        &:after {
            display: inline-block;
            align-self: center;
            @include make-icon;
            content: '\f012';
            color: $gray5;
            float: right;
            font-size: .2rem;
            width: 1.5em;
            text-align: center;
        }
    }
}

.input,
.textarea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > input,
    > textarea {
        width: 1%;
        flex: 1 1 auto;
        max-width: 100%;
        text-align: inherit;
        line-height: $inputHeight;
        padding: 0 $padding-small;

        appearance: none;
        border: 0;
        outline: 0;
        background-color: transparent;
    }
    > .f-icon {
        min-width: .6rem;
        text-align: center;
    }
}

.select {
    > select {
        appearance: none;
        outline: 0;
        &::-webkit-input-placeholder {
            opacity: .8;
        }
    }
}
