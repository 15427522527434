@charset "utf-8";
/**
 * Created by henian.xu on 2019/1/3.
 *
 */

.money-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $padding * 2 $padding;
    background-color: #fff;
    min-height: 2.4rem;
    //margin-bottom: $margin-big;
    border-bottom: 1px solid $color-border;

    > .label {
        color: $gray6;
    }
    > .money {
        color: $color-price;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2;
        /*&:before {
            content: '$';
            margin-right: .05rem;
            font-size: 70%;
        }*/
    }
    > .level {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: $color-main;
        //margin-top: $margin-big;
        margin-bottom: $margin-big;

        .level-icon {
            width: 1.5rem;
            height: 1.5rem;
            overflow: hidden;
            border-radius: 50%;
            // border: 1px solid $color-main;
        }
        > .recommend-code {
            background-color: $gray2;
            color: $gray7;
            padding: 0 $padding-small;
            border: 1px solid $color-border;
            border-radius: 0.1rem;
            margin-top: $margin-small;
            font-size: 80%;
        }
    }
    > .ctrl {
        align-self: flex-end;
    }
}
