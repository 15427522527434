/**
 * Created by henian.xu on 2017/11/11.
 *
 */

@keyframes up-in {
    0% {
        transform: translate(0, 25%);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes up-out {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, -100%);
    }
}

@keyframes footer-in {
    0% {
        //opacity: 0;
        height: 0;
    }
    100% {
        //opacity: 1;
        height: 1rem;
    }
}

@keyframes footer-out {
    0% {
        //opacity: 1;
        height: 1rem;
    }
    100% {
        //opacity: 0;
        height: 0;
    }
}
@keyframes second-in {
    0% {
        //opacity: 0;
        height: 0;
    }
    100% {
        //opacity: 1;
        height: 0.8rem;
    }
}

@keyframes second-out {
    0% {
        //opacity: 1;
        height: 0.8rem;
    }
    100% {
        //opacity: 0;
        height: 0;
    }
}

@keyframes down-in {
    0% {
        transform: translate(0, -100%);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes down-out {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 25%);
    }
}

@keyframes left-in {
    0% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes left-out {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-25%, 0);
    }
}

@keyframes right-in {
    0% {
        transform: translate(-25%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes right-out {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}

@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes show-in {
    0% {
        opacity: 0;
        /*transform: scale(1.1);*/
    }
    100% {
        opacity: 1;
        /*transform: scale(1);*/
    }
}

/*上入动画*/
.up-enter-active {
    animation: up-in .5s;
}

.up-leave-active {
    z-index: 1;
    animation: up-out .5s;
}

/*下入动画*/
.down-enter-active {
    z-index: 1;
    animation: down-in .5s;
}

.down-leave-active {
    animation: down-out .5s;
}

/*左入动画*/
.left-enter-active {
    z-index: 1;
    animation: left-in .5s;
}

.left-leave-active {
    animation: left-out .5s;
}

/*右入动画*/
.right-enter-active {
    animation: right-in .5s;
}

.right-leave-active {
    z-index: 1;
    animation: right-out .5s;
}

/*菜单动画*/
.footer-enter-active {
    overflow: hidden;
    min-height: inherit !important;
    animation-fill-mode: both;
    animation: footer-in .5s;
}

.footer-leave-active {
    overflow: hidden;
    min-height: inherit !important;
    z-index: 1;
    animation: footer-out .5s;
}

.second-enter-active {
    overflow: hidden;
    min-height: inherit !important;
    animation-fill-mode: both;
    animation: second-in .5s;
}

.second-leave-active {
    overflow: hidden;
    min-height: inherit !important;
    z-index: 1;
    animation: second-out .5s;
}

/*sidebar*/
.sidebar-left-enter-active {
    animation: left-in .5s;
}

.sidebar-left-leave-active {
    animation: right-out .5s;
}

/*弹跳*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .3s reverse;
}

/*query-box*/
.query-box-enter-active {
    animation: down-in .5s;
}

.query-box-leave-active {
    animation: up-out .5s;
}

.popup-down-enter-active {
    animation: up-in .5s;
}

.popup-down-leave-active {
    animation: down-out .5s;
}

.show-enter-active {
    animation: show-in .5s;
}

.show-leave-active {
    animation: show-in .3s reverse;
}
